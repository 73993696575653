import React from 'react'
import { Outlet } from 'react-router-dom';
import './BSWithdraw.css';

const BSWithdrawMain = () => {
    return (
        <div>
            <Outlet />
        </div>
    )
}

export default BSWithdrawMain