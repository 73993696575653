
import "./aibanner.css";

const Aibanner = () => {
    return (
        <>
        <div className='aibanner d-flex flex-direction-row  text-center center ' style={{ textAlign: "center", alignContent: "center", alignItems: "center"}} >
      
        </div>
        
     </>
    )
}

export default Aibanner