import React from "react";
import "./Home.css"
import Section1 from "./section1/section1";

import Footer from "../Footer/Footer";
// import TradeToEarnBanner from "./TradeToEarnBanner/TradeToEarnBanner";
// import MeetingBanner from "./MeetingBanner/MeetingBanner";

const Home = () => {
    return (
            <>
            <Section1 /> 
            <Footer helpIcon={false} /> 
            </>   )
        };
           
           
        
         

export default Home;


 /* <Section2 /> */
            /* <MeetingBanner /> */
            /* <TradeToEarnBanner />
            <Section3 />
            <Section4 />
            <Section5/>
            */
